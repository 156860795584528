import React,{ useState } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';
import { useEffect } from 'react';

const Login = () => {

  const MobileError = () => toast.error("Please Enter Valid Mobile No");
  const PassError = () => toast.error("Please Enter Valid Password");  
  const APiError = () => toast.warning("Sorry, No user Found");
  const Invalid = () => toast.error("Invalid Login Credentials, No user found");

  const navigate = useNavigate();

  const [Mobile, setMobile] = useState('');
  const [Pass, setPass] = useState('');

  const [loader,setLoader] = useState(false);
  const [showPassword,setshowPassword] = useState(false);

  // useEffect( () => {

  //   let details = JSON.parse(localStorage.getItem("User"));
  //   console.log("User",details)
  //   if(details?.userType === 'user'){
  //     navigate("/user/book-your-seat");
  //   }else if(details?.userType === "driver"){
  //     navigate("/driver/scanner");
  //   }
  // },[] )

    console.log("Mobile==>", Mobile);
    console.log("Pass==>", Pass);

  const validate = () => {
    let FormValid = true;

    if (!Mobile) {
      FormValid = false;
      MobileError();
    }
    else if(!Pass){
    FormValid = false;
    PassError()
  }
    return FormValid;
  }


  const SendAdmin = async () => {
    setLoader(true)
    if (validate()) {

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("mobile", Mobile)
      urlencoded.append("password", Pass)

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      const result = await fetch('https://goapi.foxberry.link/v1/user/login', requestOptions)
        .then(response => response.json()).then(res => {
          console.log("API", res);
          if(res.errors === 'No user found'){
            Invalid();
            setLoader(false);
          }else if(res.user.user.role==="COORDINATOR"){
            localStorage.setItem("User", JSON.stringify(res.user));
            setLoader(false);
            navigate('/coordinator');
          }
          else if(res.user.user.role==="ADMIN"){
            localStorage.setItem("User", JSON.stringify(res.user));
            setLoader(false);
            navigate('/admin');
          }
        }).catch((error) => {
          console.error('Error:', error);
           APiError();
          setLoader(false);
        });
    } else {
      setLoader(false);
    }

  }

  return (
<>
      <ToastContainer/>

    <div className='loginNew'>
      <Container fluid className='h-100'>
        <Row className='h-100'>
          <Col md={5} className='text-center text-white  align-items-center justify-content-center left d-none d-md-flex'>
        
          </Col>
          <Col md={7} className='d-flex text-center align-items-center justify-content-center right'>
          <Card className="card p-4">
              <h1 className='mb-0'>ChatGPT Workshop</h1>
              <h4 className='mb-3'><Icon.BoxArrowInRight className="me-2"/>Log in</h4>

            <Form className="px-4">

                <Form.Control  placeholder="Mobile Number" value={Mobile} onChange={(e) =>  {
                    if(!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10){
                      setMobile(e.target.value)
                    }
                } } className="mb-3" data-aos="fade-up" data-aos-delay="100"/>
               
                <InputGroup className='mb-3' data-aos="fade-up" data-aos-delay="200">
                  <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" value={Pass} onChange={(e) => setPass(e.target.value)}  />
                  <InputGroup.Text>{ showPassword ? <Icon.EyeSlash style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> : <Icon.Eye style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> }</InputGroup.Text>
                </InputGroup>

                <Button className="btn-submit" type="submit" onClick={(e) => SendAdmin(e.preventDefault())} data-aos="fade-up" data-aos-delay="300" >{ loader ? <Spinner animation="border" variant="light" /> : 'Login' }</Button>

              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>



  
    </>

  )
}

export default Login;