import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';

const AddCoordinator = () => {
    
   const CoordinatorAdded=()=>toast.success("Co-ordinator Added Successfully !");
   const CoordinatorAddFailed=()=>toast.error("Sorry, failed to add co-ordinator");
   const CoordinatorAlready=()=>toast.error("Sorry, This Coordinator Already Exist");

    const [coordinator, setCoordinator]=useState({
        firstName:"", lastName:"", mobile:"", pass:"", gender:"",
      });

      const handleCoordinator = (e) => {
        let name, value;
        console.log(e);
        name=e.target.name;
        value=e.target.value;
        setCoordinator({...coordinator, [name]:value});
      }
      console.log("Event Data", coordinator);

   /* ---- Add Co-ordinator Validation ----- */
   const [firstNameErr, setFirstNameErr] = useState(false);
   const [lastNameErr, setLastNameErr ] = useState(false);
   const [mobileErr, setMobileErr] = useState(false);
   const [passErr, setPassErr] = useState(false);
   const [genderErr, setGednerErr] = useState(false);
      const validate=()=>{
        console.log("In validate");
        let FormValid = true;
    
          if(coordinator.mobile.length !== 10){
            FormValid = false;
            setMobileErr(true);
          }
      
        if(coordinator.firstName.length===0){
          FormValid = false;
          setFirstNameErr(true);
        }
        if(coordinator.lastName.length===0){
          FormValid = false;
          setLastNameErr(true);
        }
        if(coordinator.pass.length===0){
            FormValid = false;
            setPassErr(true);
        }
        if(coordinator.gender.length===0){
            FormValid = false;
            setGednerErr(true);
        }
      
      return FormValid;
      }

      /* ---- API Call for Adding Co-ordinator ---- */
      const handleAddCoordinator= async()=>{
        setFirstNameErr(false);
        setLastNameErr(false);
        setMobileErr(false);
        setPassErr(false);
        setGednerErr(false);
        
        if(validate()){
        const{firstName, lastName, mobile, gender} = coordinator;
         await fetch(process.env.REACT_APP_GOA_SPIRITUAL + `/user/insertuser`, {
                method:'POST',
                headers:{"Content-Type" : "application/json"},
                body:JSON.stringify({
                    first_name:firstName,
                    last_name:lastName,
                    mobile:mobile,
                    gender:gender,
                    role:"COORDINATOR"
                })
            }).then(res=>res.json()).then(
                    response=>{
                        console.log("Response from Add Coordinator API", response);
                         if(response.status==200){
                            CoordinatorAdded();
                         }
                        else if(response.message==="Coordinator already exists" && response.status===500){
                         CoordinatorAlready();
                        }
                      else if(response.status===500){
                        CoordinatorAddFailed();
                      }}
                ).catch(err=>{
                    console.log("Error from Add Coordinator API", err);
                    toast.error(err);
                })
      }}

  return (
    <div>

    <ToastContainer/>
      
      <Container data-aos="fade-up">
      <div className='d-flex align-items-center mb-4'>
       <Icon.PersonPlus className='me-2' size={25}/>
      <h2 className='mb-0'>Add Coordinator</h2>
      </div>

          <Row className='text-center'>
          <Col md={4}>
             <FloatingLabel label="First Name" className="mb-3">
                <Form.Control placeholder="First Name" name='firstName' value={coordinator.firstName} onChange={ (e) => {
                  if(!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) && e.target.value.trim()){
                    handleCoordinator(e)
                  }
                  else if(e.target.value.length===0){
                    handleCoordinator(e)
                  }
                }}/>
                {firstNameErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid First Name</p>
                  ) : null}
            </FloatingLabel>
            </Col>
            <Col md={4}>
            <FloatingLabel label="Last Name" className="mb-3">
                <Form.Control  placeholder="Last Name" name='lastName' value={coordinator.lastName} onChange={ (e) => {
                  if(!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/)&& e.target.value.trim()){
                    handleCoordinator(e)
                  }else if(e.target.value.length === 0){
                    handleCoordinator(e)
                  }
                  }}/>
                     {lastNameErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid Last Name</p>
                  ) : null}
                </FloatingLabel>
            </Col>

            <Col md={4}>
            <FloatingLabel label="Mobile Number" className="mb-3">
                <Form.Control  placeholder="Mobile Number" name='mobile' value={coordinator.mobile} onChange={(e)=>{
                       if (!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) && e.target.value.length <= 10)
                        handleCoordinator(e)
                    }}/>
                    {mobileErr ? (
                          <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                            Enter Valid Mobile Number</p>
                          ) : null}
            </FloatingLabel>
            </Col>

            <Col md={4}>
            <FloatingLabel label="Enter Password" className="mb-3">
                <Form.Control  placeholder="Enter Password" name='pass' value={coordinator.pass} onChange={ (e) => {
                  if(e.target.value.trim()){
                    handleCoordinator(e)
                  }
                  else if(e.target.value.length===0){
                    handleCoordinator(e)
                  }
                  }}/>
                   {passErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid Valid Password</p>
                  ) : null}
            </FloatingLabel>
            </Col>

            <Col md={4}>
            <FloatingLabel  label="Gender" className="mb-3">
                <Form.Select name='gender' value={coordinator.gender} onChange={handleCoordinator}>
                    <option value="" selected disabled>Select any one</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </Form.Select>
                {genderErr ? (
                          <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                            Please select any gender</p>
                          ) : null}
            </FloatingLabel>
            </Col>

          <div>
          <Button className='btn-submit d-flex align-items-center mx-auto px-3' onClick={handleAddCoordinator}>
            <Icon.PersonAdd className='me-2'/>Add</Button>
          </div>

         </Row>
      </Container>
      </div>
  )
}

export default AddCoordinator;