import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const EnterMobile = () => {
  const navigate = useNavigate();

  const [mobileErr, setMobileErr] = React.useState(false);



      /* ------------ Send OTP -----------*/
      const [mobile, setMobile]= useState('');
      console.log("Mobile Number", mobile);
      const otpSent=()=>toast.success("OTP Sent Successfully !");
      const otpNotSent=()=>toast.error("Sorry, OTP not Sent");


  /* -------- Mobile Number Validation Code ------ */
      const validate = () => {
        console.log("In validate");
        let FormValid = true;
        if(mobile.length<10){
          FormValid=false;
          setMobileErr(true);
      }
      return FormValid;
      }

/* ------ API Call for Sending OTP ------- */
      const sendOtp= async()=>{
        if(validate()){

        console.log("in Send OTP");
        await fetch(process.env.REACT_APP_GOA_SPIRITUAL + '/user/checklogin', {
          method:'POST',
          headers:{"content-Type":"application/json"},
          body:JSON.stringify({
            mobile:mobile,
         })
        }).then(res=> res.json()).then(
          response=>{
            console.log("OTP Send Response", response)
            if(response==="Error in check login"){
                otpNotSent();   
            }
            else{
              otpSent();
              setTimeout(() => {
                navigate("/admin/enter-otp", {state:mobile});
              }, "3000")
            }
          }
        ).catch(err=>
          console.log("Error While SendOtp", err)
        )

        }
      }


  return (

    <div>

      <ToastContainer/>
         
        <Container data-aos="fade-up">
        <div className='d-flex align-items-center mb-4'>
         <Icon.PersonPlus className='me-2' size={25}/>
        <h2 className='mb-0'>Register user with OTP</h2>
        </div>
            <Row>
                <Col md={4} className='text-center'>
          
                <FloatingLabel label="Mobile Number" className="mb-3">
                <Form.Control placeholder="Mobile Number" name='mobile'  value={mobile} onChange={(e)=>{
                       if (!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) && e.target.value.length <= 10)
                        setMobile(e.target.value)
                      // {mobile.length>10 ? setMobileErr(true):setMobileErr(false)}
                    }}/>
                    {mobileErr ? (
                          <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                            Enter Valid Mobile Number</p>
                          ) : null}
                </FloatingLabel>
                <Button className='btn-submit' onClick={sendOtp}>Send OTP</Button>

                </Col>
             </Row>

    
          

        </Container>
    </div>
  )
}

export default EnterMobile;