import React from 'react'
import { Container, Row } from 'react-bootstrap';

const CoordinatorHome = () => {
  return (
    <div>
        <Container>
            <Row data-aos="fade-up">
                <h1>Hey Co-ordinator, Welcome to ChatGPT Workshop !</h1>
            </Row>
        </Container>
        
    </div>
  )
}

export default CoordinatorHome;