import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';
import { Button, Spinner, Accordion } from 'react-bootstrap';


const CoordinatorNav = () => {


  const [userDetails,setUser] = useState('');

  useEffect(() => {
     getLoginDetails();
  }, [])

   const getLoginDetails=()=>{
    const loginDetails = JSON.parse(localStorage.getItem("User"));
    console.log("loginDetails", loginDetails);
    setUser(loginDetails.user)
   }

  function setnav(){
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");
  
    const visibility = mobilenav.getAttribute('data-visible' );
    if(visibility ==="false"){
        mobilenav.setAttribute("data-visible" , true);
        toggle.setAttribute("aria-expanded", true);
        document.querySelector("body").style.overflow='hidden';
    }
    else if(visibility === "true"){
        mobilenav.setAttribute("data-visible" , false);
        toggle.setAttribute("aria-expanded", false);
        document.querySelector("body").style.overflow='auto';
        
    }
  }

  const naviagte = useNavigate();
  const [loader,setLoader] = useState(false);
 const handleLogout=()=>{
  setLoader(true);
  localStorage.clear();
  naviagte('/');
  setLoader(false);
 }

  return (

    <nav>
    {
      userDetails.role==="COORDINATOR" ? <Link to="/coordinator"><h5 className='mb-0'>Co-ordinator</h5></Link>
      : <Link to="/admin"><h5 className='mb-0'>Admin</h5></Link>
    }

    <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false"  onClick={setnav}>
        <div className='menu-btn__burger'></div>
    </div>
 
    <ul className="lg-nav">
    <Button className='btn btn-logout' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft className='me-2'/>Logout</>}</Button>
    </ul>
    
    <ul id="selectNav" className="mobile-nav" data-visible="false">
    <div className="sidenav-lg">
    <Accordion defaultActiveKey="0" flush className="">

      {/*------ Co-ordinator SideBar Menu's -------- */}
      { userDetails.role==="COORDINATOR" ?
      <>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Manage Events</Accordion.Header>
        <Accordion.Body>

        <Link to="/coordinator/scanner"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Scanner</Button></Link>
            <Link to="/coordinator/scanner-list"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Scanned Event List</Button></Link>
        </Accordion.Body>
      </Accordion.Item>
      
      <Accordion.Item eventKey="1">
           <Accordion.Header>Manage Roles</Accordion.Header>
           <Accordion.Body>
           <Link to="/admin/enter-mobile"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Register User</Button></Link>
            <Link to="/admin/add-member-attendance"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Register User without OTP</Button></Link>
           </Accordion.Body>
         </Accordion.Item>
      </>
      
      :""}

    {/*------ Admin SideBar Menu's -------- */}
      {userDetails.role==="ADMIN" ? 
      <> 
      <Accordion.Item eventKey="0">
        <Accordion.Header>Manage Events</Accordion.Header>
        <Accordion.Body>
        <Link to="/admin/add-event"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.CalendarPlus className='me-2'/>Add Event</Button></Link>
            <Link to="/admin/all-event-list"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
              <Icon.ListUl className="me-2"/>All Event List</Button></Link>
        </Accordion.Body>
      </Accordion.Item>

           <Accordion.Item eventKey="1">
           <Accordion.Header>Manage Roles</Accordion.Header>
           <Accordion.Body>
           <Link to="/admin/add-coordinator"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Add Co-ordinator</Button></Link>
           <Link to="/admin/enter-mobile"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Register User</Button></Link>
            <Link to="/admin/add-member-attendance"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Register User without OTP</Button></Link>
           </Accordion.Body>
         </Accordion.Item>
         </>

      :""
      }
    </Accordion>
      </div>

      <Button className='btn btn-logout m-5' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft className='me-2'/>Logout</>}</Button>
    </ul>
    
</nav>
  )
}

export default CoordinatorNav;