import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import CoordinatorNav from '../Components/Coordinator/CoordinatorNav';
import Sidebar from '../Components/Coordinator/Sidebar';
import { Outlet } from 'react-router-dom';

const AdminLayout = () => {
  return (
    <div>
    <CoordinatorNav/>
    <Container fluid className=''>
    <Row>
    <div className='sidenav-lg-parent'>
    <Col sm={12} lg={1} className='ps-0 d-none d-lg-block'>
      <Sidebar/>
    </Col>
      <Col sm={12} lg={10}>
        <div className='outletDiv px-3'>
        <Outlet/>
      </div>
      </Col>
      </div>
    </Row>
  </Container>
  </div>
  )
}

export default AdminLayout;