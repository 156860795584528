import React, {useState,useEffect} from "react";
import { Accordion, Button } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";


const Sidebar = () => {

    const navigate =  useNavigate();
    const [userDetails, setUserDetails] = useState('');

    useEffect(() => {
      getLoginDetails();
   }, []);

 
    const getLoginDetails=()=>{
     const loginDetails = JSON.parse(localStorage.getItem("User"));
     console.log("loginDetails", loginDetails);
     setUserDetails(loginDetails.user);
    }

  return (
    <>
    <div className="sidenav-lg">
    <Accordion defaultActiveKey="0" flush>

      {/*------ Co-ordinator SideBar Menu's -------- */}
      { userDetails.role==="COORDINATOR" ?
      <>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Manage Events</Accordion.Header>
        <Accordion.Body>
          <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/coordinator/scanner")}}>
            <Icon.QrCode className="me-2"/>Scanner</Button>
            <Button className="w-100 mb-2 btn-accordionMenu"  onClick={ () => {navigate("/coordinator/scanner-list")}}>
            <Icon.ListCheck className="me-2"/>Scanned Event List</Button>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
           <Accordion.Header>Manage Roles</Accordion.Header>
           <Accordion.Body>
           <Button className="w-100 mb-3 btn-accordionMenu" onClick={ () => {navigate("/admin/enter-mobile")}}>
               <Icon.PersonAdd className='me-2'/>Register User</Button>
               <Button className="w-100 mb-0 btn-accordionMenu" onClick={ () => {navigate("/admin/add-member-attendance")}}>
               <Icon.PersonAdd className='me-2'/>Register User without OTP</Button>
           </Accordion.Body>
         </Accordion.Item>
      </>
      
      :""}

    {/*------ Admin SideBar Menu's -------- */}
      {userDetails.role==="ADMIN" ? <>
        <Accordion.Item eventKey="0">
        <Accordion.Header>Manage Events</Accordion.Header>
        <Accordion.Body>
        <Button className="w-100 mb-3 btn-accordionMenu" onClick={ () => {navigate("/admin/add-event")}}>
            <Icon.CalendarPlus className='me-2'/>Add Event</Button>
            <Button className="w-100 mb-0 btn-accordionMenu" onClick={()=>{navigate("/admin/all-event-list")}}>
              <Icon.ListUl className="me-2"/>All Event List</Button>
                    {/* <Button className="w-100 mb-2" onClick={ () => {navigate("/admin/edit-event")}}>
            <Icon.PencilSquare className='me-2'/>Edit Event</Button> */}
        </Accordion.Body>
      </Accordion.Item>

           <Accordion.Item eventKey="1">
           <Accordion.Header>Manage Roles</Accordion.Header>
           <Accordion.Body>
           <Button className="w-100 mb-3 btn-accordionMenu" onClick={ () => {navigate("/admin/add-coordinator")}}>
               <Icon.PersonAdd className='me-2'/>Add Co-ordinator</Button>
           <Button className="w-100 mb-3 btn-accordionMenu" onClick={ () => {navigate("/admin/enter-mobile")}}>
               <Icon.PersonAdd className='me-2'/>Register User</Button>
               <Button className="w-100 mb-0 btn-accordionMenu" onClick={ () => {navigate("/admin/add-member-attendance")}}>
               <Icon.PersonAdd className='me-2'/>Register User without OTP</Button>
           </Accordion.Body>
         </Accordion.Item>

         </>
      :""
      }
    </Accordion>
      </div>
    </>
  );
};

export default Sidebar;
