import React from 'react';
import CoordinatorNav from '../Components/Coordinator/CoordinatorNav';
import { Container,Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Components/Coordinator/Sidebar';

const CoordinatorLayout = () => {
  return (
    <div>
      <CoordinatorNav/>
      <Container fluid className=''>
      <Row>
      <div className='sidenav-parent'>
      <Col lg={1} className='ps-0 d-none d-lg-block'>
        <Sidebar/>
      </Col>
        <Col sm={12} lg={10}>
          <div className='outletDiv px-3'>
          <Outlet/>
        </div>
        </Col>
        </div>
      </Row>
    </Container>
    </div>
  )
}

export default CoordinatorLayout;