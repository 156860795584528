import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import QrReader from "react-web-qr-reader";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
const Scanner = () => {
  const NotRegistered = () =>
    toast.error("Sorry, This user is not registered for event");
  const SuccessRegistered = () => toast.success("Participant Confirmed !");

  const InvalidQR = () => toast.error("Scanned QR is Invalid")

  const [facingMode, setfacingMode] = useState("environment");
  const [result, setResult] = useState("");

  const [userDetails, setUser] = useState("");
  useEffect(() => {
    getLoginDetails();
  }, []);

  const getLoginDetails = () => {
    const loginDetails = JSON.parse(localStorage.getItem("User"));
    console.log("loginDetails", loginDetails);
    setUser(loginDetails.user);
  };

  /* ---- State for handling QR POP-UP---*/
  const [showQrDetails, setShowQrDetails] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [modalData, setModalData] = useState("");

  const handleScan = (data) => {
    console.log(
      "--------------React QR Reader Data-----------",
      data.data.user_id
    );
    console.log("UserDetaols", userDetails.user_id);
    try {
      if (data != null || data !== undefined) {
        console.log("Event data", JSON.parse(data.data));
        let val = JSON.parse(data.data);
        if (val.user_id !== null && val.user_id !== undefined) {
          setShowQrDetails(true);
          setModalData(JSON.parse(data.data));
        }
      }
    } catch (e) {
      console.log(e);
      InvalidQR()
    }
  };

  const handleEror = (err) => {
    console.log(err);
  };

  const previewStyle = {
    height: 400,
    width: 400,
    display: "flex",
    justifyContent: "center",
  };

  const camStyle = {
    display: "flex",
    justifyContent: "center",
  };

  console.log("ModalData", modalData);
  /* -------- Confirm Event Attendance by QR -------- */

  const ConfirmEventAttendance = async (e) => {
    await fetch("https://goapi.foxberry.link/v1/registerevent/set/attendance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: modalData.user_id,
        event_id: modalData.event_id,
        mobile: modalData.mobile,
        scannedBy: userDetails.mobile,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("data", response);
        if (response.message === "You have already attended") {
          setShowConfirmed(true);
        } else if (response === "Sorry user not registrated") {
          NotRegistered();
          setShowQrDetails(false);
        } else if (response.message === "Attended Successfully") {
          SuccessRegistered();
          setShowQrDetails(false);
        }
      })
      .catch((err) => {
        console.log("Error while Confirming", err);
      });
  };

  return (
    <>
      <div>
        <ToastContainer />
        <Container data-aos="fade-up">
          <Row>
            <h2>QR CODE SCANNER</h2>
          </Row>

          <Row className="mt-3">
            <Col md={10}>
              <div style={camStyle}>
                <QrReader
                  delay={5000}
                  style={previewStyle}
                  onError={handleEror}
                  onScan={handleScan}
                  facingMode={facingMode}
                />
              </div>
              <Row>
                <Col className="d-flex justify-content-center mt-4">
                  <Button
                    onClick={() => setfacingMode("user")}
                    className="btn-camera me-2"
                  >
                    Front Camera
                  </Button>
                  <Button
                    onClick={() => setfacingMode("environment")}
                    className="btn-camera"
                  >
                    Back Camera
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ---------- Modal For Showing Scanned QR Data of Event -------------- */}
      <Modal
        show={showQrDetails}
        onHide={() => setShowQrDetails(false)}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Scanned QR Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            <Icon.Person className="me-2" />
            Candidate Name:- {modalData.name}
          </h4>
          <h4>
            <Icon.Telephone className="me-2" />
            Candidate Mobile Number:- {modalData.mobile}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowQrDetails(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={ConfirmEventAttendance}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ---------- Modal For Showing If Event QR is Already Scanned or confirmed -------------- */}
      <Modal
        show={showConfirmed}
        onHide={() => {
          setShowConfirmed(false);
          setShowQrDetails(false);
        }}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Already Confirmed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            <Icon.Person className="me-2" />
            Candidate Name:- {modalData.name}
          </h4>
          <h4>
            <Icon.Telephone className="me-2" />
            Candidate Mobile Number:- {modalData.mobile}
          </h4>
          <h2>This Event QR is alredy Confirmed or Scanned</h2>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmed(false);
              setShowQrDetails(false);
            }}
          >
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Scanner;
