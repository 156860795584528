import React, { useState } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const EnterOtp = () => {
    const location = useLocation();
    const mobile = location.state;
    console.log("Sent Mobile Number", mobile);

    const otpVerified=()=>toast.success("OTP Verified !");
    const otpNotVerified=()=>toast.error("OTP Not Verified");
    const otpVerifiedFailed =()=>toast.error("Failed to verify failed");

    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    console.log("Entered OTp", otp);


    const [otpErr, setOtpErr] = React.useState(false);
 /* -------- Mobile Number Validation Code ------ */
  const validate = () => {
    console.log("In validate");
    let FormValid = true;
    if(otp.length<6){
      FormValid=false;
      setOtpErr(true);
  }
  return FormValid;
 }

 /* -------- API For verifying OTP --------- */
    const verifyOtp = async() =>{
      if(validate()){
        await fetch(process.env.REACT_APP_GOA_SPIRITUAL + '/user/verifylogin', {
            method:'POST',
            headers:{"content-Type":"application/json"},
            body:JSON.stringify({
              mobile:mobile,
              otp:otp
           })
          }).then(res=> res.json()).then(
            response=>{
              console.log("OTP Verify Response", response)
              if(response.message === "OTP Match"){
                otpVerified();
                setTimeout(() => {
                  navigate("/admin/add-member-attendance", {state:mobile});
                }, "3000");
              }
              else{
                otpNotVerified();
              }
            }
          ).catch(err=>{
            console.log("Error While SendOtp", err);
            otpVerifiedFailed();
          }
          )
        }
    }
  return (
    <div>


      <ToastContainer/>
      
        <Container data-aos="fade-up">
        <div className='d-flex align-items-center mb-4'>
        <Icon.PersonPlus className='me-2' size={25}/>
        <h2 className='mb-0'>Register user with OTP</h2>
        </div>
            <Row>
            <Col md={4} className='text-center' data-aos="fade-up">
          <FloatingLabel label="Enter OTP" className="mb-3">
          <Form.Control placeholder="Enter OTP" value={otp} onChange={(e)=>{
                       if (!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) && e.target.value.length <= 6)
                        setOtp(e.target.value)
                      // {otp.length>6 ? setOtpErr(true):setOtpErr(false)}
                    }}/>
                        {otpErr ? (
                          <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                            Enter Valid 6 digit otp</p>
                          ) : null}
          </FloatingLabel>
          <Button className='btn-submit' onClick={verifyOtp}>Verify OTP</Button>

          </Col>
            </Row>
        </Container>
    </div>
  )
}

export default EnterOtp