import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const AddMember = () => {

    const location = useLocation();
    const mobile = location.state;
    console.log("Sent Mobile Number", mobile);



    const [userDetails,setUser] = useState('');
    const [whatsAppMobile,setWhatsAppMobile] = useState('');
    const [live,setLive] = useState('');
    const [email,setEmail] = useState('');
     useEffect(() => {
     getLoginDetails();
     }, [])

   const getLoginDetails=()=>{
    const loginDetails = JSON.parse(localStorage.getItem("User"));
    console.log("loginDetails", loginDetails);
    setUser(loginDetails.user)
   }

const AlreadyAttended=()=>toast.error("This participant has already attended to event");
const MemberAlreadyResgitered=()=>toast.error("This participant has already registered to event");
const MemberRegistered=()=>toast.success("Participant Successfully Registered !");
const UserAlreadyExist =()=>toast.error("This user is already resgitered for event")

   console.log("Mobile Number of Scanned By", userDetails.mobile);

    // const [allEventData, setallEventData ] = useState([]);
  
    // useEffect(() => {
    //     fetchEvents();
    //   }, []);
      
    // const fetchEvents = async () => {
    //     await fetch(process.env.REACT_APP_GOA_SPIRITUAL+`/event/get/all/events`, {
    //       method: "GET",
    //       headers: { "Content-Type": "application/json" },
    //     })
    //       .then((res) => {
    //         if (res.status === 200) 
    //         return res.json();
    //       })
    //       .then((data) => {
    //         console.log(" All Event Data Without Pagination", data);
    //         setallEventData(data.data);
    //       })
    //       .catch((err) => console.log("ERR", err));
    //   };

    //   console.log("All Event Data", allEventData);

      const [registerUser, setRegisterUser]=useState({
        eventId:"", firstName:"", lastName:""
      });
      const [enableMobile,setEnableMobile] = useState('');

      const handleRegisterUser = (e) => {
        let name, value;
        console.log(e);
        name=e.target.name;
        value=e.target.value;
        setRegisterUser({...registerUser, [name]:value});
      }
      console.log("Event Data", registerUser);

      /* -------- Error Variables for Handling Inputs ---------- */
      const [mobileErr, setMobileErr] = useState(false);
      const [firstNameErr, setFirstNameErr] = useState(false);
      const [lastNameErr, setLastNameErr ] = useState(false);
      const validate=()=>{
        console.log("In validate");
        console.log("first", registerUser.firstName.length)
        let FormValid = true;
        if(!mobile){
          if(enableMobile.length !== 10){
            FormValid = false;
            setMobileErr(true);
          }
        }
        if(registerUser.firstName.length===0){
          FormValid = false;
          setFirstNameErr(true);
        }
        if(registerUser.lastName.length===0){
          FormValid = false;
          setLastNameErr(true);
        }
      
      return FormValid;
      }


      const AddRegisterUser = async (e) =>{
        setMobileErr(false)
        setFirstNameErr(false)
        setLastNameErr(false)
        if(validate()){
        e.preventDefault();
        const{firstName, lastName,} = registerUser;
       
       await fetch(process.env.REACT_APP_GOA_SPIRITUAL+`/registerevent/add/event/member/add/user`, {
         method: "POST",
         headers: {
           "Content-Type" : "application/json"
         },
         body:JSON.stringify({
            event_id:EventId,
            mobile:mobile ? (mobile) : (enableMobile),
            first_name:firstName.trim(),
            last_name:lastName.trim(),
            whatsAppMobile:whatsAppMobile,
            email:email,
            live:live.trim(),
            scannedBy:userDetails.mobile
         })
       }).then(res=>res.json()).then(response=>{
            console.log("Response from /registerevent/add/event/member/add/user", response)
            if(response.message==="Already attended"){
                AlreadyAttended();
            }
            else if(response.message==="Member already added"){
                MemberAlreadyResgitered();
            }
            else if(response.messageAttend==="Attended Successfully"){
               MemberRegistered();
            }
            else if(response.message==="User already exists"){
                UserAlreadyExist();
            }
            setEnableMobile('')
            setRegisterUser({
              ...registerUser,
              firstName:'',
              lastName:'',
              eventId:''
            })
       }).catch(err=>{
             console.log("error while Register User & Attendance", err);
             toast.error(err);
       }
       )
   }
  }

   const EventId="EVENT_CHATGPT_1674754779806";
  return (
    <div>

    <ToastContainer/>
      
      <Container data-aos="fade-up">
      <div className='d-flex align-items-center mb-4'>
       <Icon.PersonPlus className='me-2' size={25}/>
      <h2 className='mb-0'>{mobile ? "Register user with OTP" : "Register user without OTP"}</h2>
      </div>
          <Row>
            <Col md={4}>
             <FloatingLabel label="Event" className="mb-3">
             <Form.Control placeholder="Event" disabled={true} value={EventId}/>
                {/* <Form.Select name='eventId' value={registerUser.eventId} onChange={handleRegisterUser} className="mb-3">
                    <option value="" selected disabled>Event Id</option>
                    {allEventData!==undefined && allEventData.length>0 ?
                     allEventData.map((row,index)=><option key={index} value={row.event_id}>{row.event_id}</option>)
                    :<option>No data Found</option>}
                </Form.Select> */}
            </FloatingLabel>
            </Col>

            <Col md={4}>
            <FloatingLabel label="Mobile Number" className="mb-3">
                { mobile ? <Form.Control placeholder="Mobile Number" disabled={true} value={mobile}/> : 
                <Form.Control placeholder="Mobile Number" value={enableMobile} onChange={(e)=>{
                  if (
                    !e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) && e.target.value.length <= 10
                  ){ setEnableMobile(e.target.value) }
                }}/>
                }
                  {mobileErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid Mobile Number</p>
                  ) : null}
            </FloatingLabel>
            </Col>
        </Row>

        <Row>
        <Col md={4}>
            <FloatingLabel label="Whats App Mobile Number" className="mb-3">
                <Form.Control placeholder="Whats App Mobile Number" value={whatsAppMobile} onChange={(e)=>{
                  if (
                    !e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) && e.target.value.length <= 10
                  ){ setWhatsAppMobile(e.target.value) }
                }}/>
                  {mobileErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid Whats App Mobile Number</p>
                  ) : null}
            </FloatingLabel>
            </Col>

            <Col md={4}>
            <FloatingLabel label="Enter Email" className="mb-3">
                <Form.Control placeholder="Enter Email Address" name="email" value={email} onChange={(e)=>{{ setEmail(e.target.value) }
                }}/>
              
            </FloatingLabel>
            </Col>
        </Row>
        <Row>
        <Col md={8}>
            <FloatingLabel label="Enter City" className="mb-3">
                <Form.Control placeholder="Enter city" name="city" value={live} onChange={(e)=>{setLive(e.target.value)}}/>
            </FloatingLabel>
            </Col>
        </Row>

        <Row>
            <Col md={4}>
             <FloatingLabel label="First Name" className="mb-3">
                <Form.Control placeholder="First Name" name='firstName' value={registerUser.firstName} onChange={ (e) => {
                  if(!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) && e.target.value.trim()){
                    handleRegisterUser(e)
                  }
                  else if(e.target.value.length===0){
                    handleRegisterUser(e)
                  }
                }}/>

                {firstNameErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid First Name</p>
                  ) : null}
            </FloatingLabel>
            </Col>

            <Col md={4}>
            <FloatingLabel label="Last Name" className="mb-3">
                <Form.Control  placeholder="Last Name" name='lastName' value={registerUser.lastName} onChange={ (e) => {
                  if(!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/)&& e.target.value.trim()){
                    handleRegisterUser(e)
                  }else if(e.target.value.length === 0){
                    handleRegisterUser(e)
                  }
                  }}/>
                     {lastNameErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid Last Name</p>
                  ) : null}
                </FloatingLabel>
            </Col>
        </Row>
        <Button className='btn-submit' onClick={AddRegisterUser}>Register</Button>
        </Container>
    </div>
  )
}

export default AddMember;