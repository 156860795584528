import React from 'react'
import { Container, Row } from 'react-bootstrap';

const AdminHome = () => {
  return (
    <div>
        <Container>
            <Row data-aos="fade-up">
                <h1>Hey Admin, Welcome to ChatGPT Workshop !</h1>
            </Row>
        </Container>
        
    </div>
  )
}

export default AdminHome;