
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";


const ScannerList = () => {
  // const defaultSorted = [
  //   {
  //     dataField: "name",
  //     order: "desc",
  //   },
  // ];

  // const pagination = paginationFactory({
  //   page: 1,
  //   sizePerPage: 10,
  //   lastPageText: ">>",
  //   firstPageText: "<<",
  //   nextPageText: ">",
  //   prePageText: "<",
  //   showTotal: true,
  //   alwaysShowAllBtns: true,
  //   onPageChange: function (page, sizePerPage) {
  //     console.log("page", page);
  //     console.log("sizePerPage", sizePerPage);
  //   },
  //   onSizePerPageChange: function (page, sizePerPage) {
  //     console.log("page", page);
  //     console.log("sizePerPage", sizePerPage);
  //   },
  // });

  // const columns = [
  //   {
  //     dataField: "id",
  //     text: "Sr. No.",
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: "20%", backgroundColor: "#cfe2ff" };
  //     },
  //     formatter: (_, row, index) => {
  //               return (index + 1);
  //           }
  //   },
  //   {
  //     dataField: "first_name",
  //     text: "Name",
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: "40%", backgroundColor: "#cfe2ff" };
  //     },
  //     formatter: (_, row) => {
  //       return (
  //           <span>
  //               {row.first_name} {row.last_name}
  //           </span>
  //       );
  //   }
  //   },
  //   {
  //     dataField: "mobile",
  //     text: "Mobile",
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: "40%", backgroundColor: "#cfe2ff" };
  //     },
  //   },
  // ];

//   const [userDetails,setUser] = useState('');
//   useEffect(() => {
//   getLoginDetails();
  
//   }, [])

// const getLoginDetails=()=>{
//  const loginDetails = JSON.parse(localStorage.getItem("User"));
//  console.log("loginDetails", loginDetails);
//  setUser(loginDetails.user)
// }

// console.log("Login Co-ordinator Mobile==>", userDetails.mobile)
  const [event, setEvent] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // const getData = () => {
  //   // let data = JSON.parse(localStorage.getItem("operator"));
  //   // console.log("------------data-------------", data);
  //   let sendData = {
  //     event_id: "EVENT_CHATGPT_1674754779806",
  //     operator: userDetails.mobile

  //   };
  //   axios
  //     .post('https://goapi.foxberry.link/v1/registerevent/get/attendance/event/all', sendData)
  //     .then((res) => {
  //       console.log("res.data",res.data)
  //       setEvent(res.data.data);
  //     });
  // };

  const getData=async()=>{
    let loginDetails=JSON.parse(localStorage.getItem("User"));
    console.log("UserDetails Mobile Number",loginDetails.user.mobile);
 await fetch(process.env.REACT_APP_GOA_SPIRITUAL + `/registerevent/get/attendance/event/all`, {
  method: "POST",
  headers: {
    "Content-Type" : "application/json"
  },
  body:JSON.stringify({
    event_id: "EVENT_CHATGPT_1674754779806",
    operator:loginDetails.user.mobile
    
  })
}).then(res=>res.json()).then(response=>{
     console.log("Response from /registerevent/get/attendance/event/all", response)
     console.log("response.data",response.data)
    setEvent(response.data);
     
}).catch(err=>{
      console.log("error while Register User & Attendance", err);
      toast.error(err);
}
)  }

  return (
    <div>
      <ToastContainer/>
      <Container data-aos="fade-up">
        <Row className="justify-content-center">
          <h2>SCANNER EVENT LIST</h2>
          <Col md={10} className='mt-3'>
            <Table hover bordered responsive>
            <thead>
        <tr>
          <th>Sr. No.</th>
          <th>Name</th>
          <th className="noselect">Mobile Number</th>
        </tr>
      </thead>
      <tbody>
        {
          event!=='undefined' && event.length!==0 ? event.map((row,index)=>{
             return(
              <tr>
                <td>{index+1}</td>
                <td>{row.first_name + " " + row.last_name}</td>
                <td className="noselect">{row.mobile}</td>
              </tr>
             )
          }):"No Events data found"
        }

      </tbody>
            </Table>


          {/* <BootstrapTable
            keyField="id"
            data={event}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
          /> */}
          </Col>
         
        </Row>
      </Container>
    </div>
  );
};

export default ScannerList;
