import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {Container, Row, Table} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const RegisterList = () => {
      const location = useLocation();
        const event_id = location.state;
        console.log("nadidwai", event_id);

        const [allMember , setAllMember] =useState([]);
        useEffect(()=>{
            getRegisteredMember();
        },[])

        const getRegisteredMember=async()=>{
            await fetch('https://goapi.foxberry.link/v1/event/get/event/member-details', {
                method:"POST",
                headers: { "Content-Type": "application/json" },
                body:JSON.stringify({ event_id:event_id })
            })
            .then(apiResponse=>apiResponse.json()).then(res=>{
                console.log("Get All Registered Member", res);
                setAllMember(res.data.member_id);
            })
        }

  return (

    <div>
        <Container data-aos="fade-up">
        <div className='d-flex align-items-center mb-4'>
        <Icon.PersonCheck className='me-2' size={25}/>
        <h2 className='mb-0'>Member registered for Somyag Event</h2>
        </div>
        <Row>
          <Table responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Member Name</th>
                <th className='noselect'>Mobile Number</th>
                <th>Gender</th>
              </tr>
            </thead>
            <tbody>
        { allMember!==undefined && allMember.length!==0 ?
         allMember.map((row,index)=>{
            return(
                <tr>
                    <td>{index+1}</td>
                    <td>{row.first_name+" "+row.last_name}</td>
                    <td className='noselect'>{row.mobile}</td>
                    <td>{row.gender}</td>
                
                </tr>
            )
         })
        :"No Member Data Found"}
        </tbody>
        </Table>
        </Row>
        </Container>
    </div>
  )
}

export default RegisterList;