import React, { useEffect, useState } from "react";
import { Button, Container, Row, Table, Col, Spinner, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const AllEventList = () => {

const navigate = useNavigate();

  const [eventList, setEventList] = useState("");
  const [data,setdata] = useState([])
  const [count, setCount] = useState();
  const [countThisPage, setCountThisPage] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [skipDocs, setSkipDocs] = useState();
  const [docPerPage, setDocsPerPage] = useState(10);

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    handlePagination(1,docPerPage);
  }, [docPerPage]);

  useEffect( () => {
    getAllEvents(pageNo,docPerPage)
  },[pageNo] )

  const getAllEvents = async (page, docPerPage) => {
    console.log("Handle page change")
    setLoader(true);
    console.log("Link", process.env.GOA_SPIRITUAL);
    await fetch(
      `https://goapi.foxberry.link/v1/event/all/list?page=${page}&documentsPerPage=${docPerPage}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setEventList(response);
        setLoader(false);
      });
  };

console.log("Event List", eventList);

/* ---------- Method for Handling Pagination ------------  */

const handlePagination = async (page, docPerPage) => {
  console.log("Handle docs per page change")
  setLoader(true);
  console.log("Link", process.env.GOA_SPIRITUAL);
  await fetch(
    `https://goapi.foxberry.link/v1/event/all/list?page=${page}&documentsPerPage=${docPerPage}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response);
      setEventList(response);
      setPageNo(1);
      setDocsPerPage(response.docPerPage);
      setLoader(false);
      setCount(response.count);
    });
};


  return (
    <div>
      <Container data-aos="fade-up">
      <div className='d-flex align-items-center mb-4'>
        <Icon.ListUl className='me-2' size={25}/>
        <h2 className='mb-0'>Event List</h2>
        </div>

        <Row>
          <Table responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Event Title</th>
                <th>Event Type</th>
                <th>Event Date</th>
                <th>Event time</th>
                <th>Registration Count</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <Spinner />
              ) : eventList && eventList.data.length > 0 ? (
                eventList.data.map((row, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{row.event_title}</td>
                      <td>{row.event_type}</td>
                      <td>{row.event_date}</td>
                      <td>{row.event_start_time}</td>
                      <td>{row.participationCount}</td>
                      <td><Button className='btn-submit' onClick={ () => {
                         navigate('/admin/event-registration',{state:row.event_id})
                      } }>Registrations</Button></td>
                    </tr>
                  );
                })
              ) : (
                "No data found"
              )}
            </tbody>
          </Table>

          {/* ------- Table Custom Pagination -------- */}

          <Row className="justify-content-between mb-5">
            <Col md={2}>
              {/* <Button onClick={()=> setDocsPerPage(docPerPage + 15) } >Row Per Pages</Button> */}
              <h6>Records Per Pages</h6>
              <Form.Select aria-label="Default select example"  onChange={(e)=>setDocsPerPage(e.target.value)}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="300">50</option>
        </Form.Select>
            </Col>
            <Col md={3} className="d-flex justify-content-end mt-3 mt-md-0">
              <Button className='me-3 btn-previous' disabled={pageNo===1?true:false} onClick={()=> setPageNo(pageNo - 1) }><Icon.ChevronDoubleLeft className="me-1"/>Back</Button>
              <Button className="btn-next" disabled={pageNo>(count/docPerPage)?true:false} onClick={()=> setPageNo(pageNo + 1) }>Next<Icon.ChevronDoubleRight className="me-1"/></Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default AllEventList;
