import React, { useEffect, useState } from 'react'
import {Container, Row, Col, Form, Button, Spinner, Card} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import S3 from "react-aws-s3";
import Compressor from 'compressorjs';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';


const AddEvent = () => {

    const [loader, setLoader] = useState(false);

    const [event, setEvent]=useState({
    title:"", category:"", via:"", venue:"", description:"", campaign:"", sponser:"", pdf:"", youtube:"", paid:"", amount:"", 
    discount:"", minCapacity:"", maxCapacity:"", startDate:"", endDate:"", startTime:"", endTime:"", lastDate:"",  medium:"", type:"",
    state:"", city:""
  });

  let name, value;
  
  const handleEvent = (e) => {
    console.log(e);
    name=e.target.name;
    value=e.target.value;
    setEvent({...event, [name]:value});
   
  }
  console.log("Event Data", event);
  


    // ---- Upload image to AWS Server ------
    
     const uploadImageToS3 = (eventImage,Imgtype) => {
        console.log("Entered into Upload Image to S3");
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            dirName: "yin/event_images",
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: "https://foxberry-images.s3.amazonaws.com"
        };
        console.log(config)

        var fileInput = false;
        const image = eventImage.target.files[0];
        if (image === undefined) return
        console.log("image data before compression-------------- ", image);
        if (eventImage.target.files[0]) {
            fileInput = true;
            console.log('New input file')
            console.log(eventImage.target.files[0].name)
            console.log(eventImage.target.files[0].type)
        }
        if (fileInput) {

            if (eventImage.target.files[0].type === 'image/png' || eventImage.target.files[0].type === 'image/jpeg') {
                new Compressor(image, {
                    quality: 0.6,
                    success: (compressedResult) => {
                        console.log("image data after result- compressedResultcompressedResultcompressedResult-------------- ", compressedResult);

                        let newFileName = eventImage.target.files[0].name.replace(/\..+$/, "");
                        const finalFileName = newFileName;
                        console.log("------- filename image upload --------", finalFileName);
                        const ReactS3Client = new S3(config);
                        // const newFileName = 'test-file';
                        console.log("File",compressedResult)
                        ReactS3Client.uploadFile(compressedResult, newFileName)
                        .then((res) => {
                            console.log(res);
                            console.log("image uploaded successfully!!!!");
                            if( Imgtype === 'campaign'){
                                setEvent({
                                    ...event,
                                    campaign:res.location

                                });
                            }
                            else if(Imgtype === 'sponser'){
                                setEvent({
                                    ...event,
                                    sponser:res.location
                                });
                            }
                            else if(Imgtype === 'pdf'){
                                setEvent({
                                    ...event,
                                    pdf:res.location
                                });
                            }
                        })
                        .catch(err => {
                            console.error(err)
                            console.log("Uploading Error.....",err)
                        });
                    },
                });
                console.log("--------------- Upload image -----------",event);

                console.log("File Link",event.campaign );
                
            }
        }
    }
/* ------- Getting all district data from Maharashtra ----- */

    useEffect(() => {
        fetchDistrict();
      }, []);

const [districtData, setDistrictData ] = useState([]);

    const fetchDistrict = async () => {
        await fetch(process.env.REACT_APP_FOXBERRY_STG_API+"/address/city/GA", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            if (res.status === 200) 
            return res.json();
          })
          .then((data) => {
            console.log(" District Data", data);
            setDistrictData(data);
          })
          .catch((err) => console.log("ERR", err));
      };

      console.log("first", districtData);



      /* -------- Error Handling & Variables for Create Event ---------- */

      const EventAdded=()=>toast.success("Event Added Successfully");
      const EventAlready= ()=>toast.error("Sorry, this event already exists");
      const EventAddFail=()=>toast.error("Sorry, failed to add event")

      const [titleErr, setTitleErr] = useState(false);
      const [viaErr, setViaErr ] = useState(false);
      const [categoryErr, setCategoryErr] = useState(false);
      const [venueErr, setVenueErr] = useState(false);
      const [campaignImgErr, setCampaignImgErr] = useState(false);
      const [sponserImgErr, setSponserImgErr] = useState(false);
      const [pdfErr, setPdfErr] = useState(false);
      const [isPaidErr, setIsPaidErr] = useState(false);
      const [amountErr, setAmountErr] = useState(false);
      const [minErr, setMinErr] = useState(false);
      const [maxErr, setMaxErr] = useState(false);
      const [minMaxErr, setMinMaxErr] = useState(false);
      const [startDateErr, setstartDateErr] = useState(false);
      const [startTimeErr, setStartTimeErr] = useState(false);
      const [endDateErr, setEndDateErr] = useState(false);
      const [endTimeErr, setEndTimeErr] = useState(false);
      const [lastDateErr, setLastDateErr] = useState(false);
      const [mediumErr, setMediumErr] = useState(false);
      const [stateErr, setStateErr] = useState(false);
      const [cityErr, setCityErr] = useState(false);

      const validate=()=>{
        console.log("In validate");
        let scrrollArray = []
        let FormValid = true;
          if(event.title.length === 0){
            FormValid = false;
            setTitleErr(true);
            scrrollArray.push('title')
            // document.getElementById('title').scrollIntoView({behavior: 'smooth'});
          }
          if(event.via.length === 0){
            FormValid = false;
            setViaErr(true);
            scrrollArray.push('via')
          }
          if(event.category.length === 0){
            FormValid = false;
            setCategoryErr(true);
            scrrollArray.push('category')
            // document.getElementById('category').scrollIntoView({ behavior: 'smooth' });
          }
          if(event.venue.length === 0){
            FormValid = false;
            setVenueErr(true);
            scrrollArray.push('venue');
            // document.getElementById('venue').scrollIntoView({ behavior:'smooth'});
          }
          if(event.campaign.length === 0){
            FormValid = false;
            setCampaignImgErr(true);
            scrrollArray.push('campaign')
            // document.getElementById('campaign').scrollIntoView({ behavior:'smooth'});
          }
          if(event.sponser.length === 0){
            FormValid = false;
            setSponserImgErr(true);
            scrrollArray.push('sponser');
            // document.getElementById('sponser').scrollIntoView({ behavior:'smooth'});
          }
          if(event.pdf.length === 0){
            FormValid = false;
            setPdfErr(true);
            scrrollArray.push('pdf');
            // document.getElementById('pdf').scrollIntoView({ behavior:'smooth'});
          }
          if(event.paid.length === 0){
            FormValid = false;
            setIsPaidErr(true);
            scrrollArray.push('isPaid');
            // document.getElementById('isPaid').scrollIntoView({ behavior:'smooth'});
          }
          if(event.paid==='yes' && event.amount.length ===0){
            FormValid = false;
            setAmountErr(true);
            scrrollArray.push('amount');
          }
          if(event.minCapacity.length === 0){
            FormValid = false;
            setMinErr(true);
            scrrollArray.push('min')
          }
          if(event.maxCapacity.length === 0){
            FormValid = false;
            setMaxErr(true);
            scrrollArray.push('max')
          }
          // console.log("min Value", event.min)
          if(event.minCapacity > event.maxCapacity){
            FormValid = false;
            setMinMaxErr(true);
            scrrollArray.push('max');
          }
          if(event.startDate.length===0){
            FormValid = false;
            setstartDateErr(true);
            scrrollArray.push('startDate');
          }
          if(event.endDate.length === 0){
            FormValid = false;
            setEndDateErr(true);
            scrrollArray.push('endDate');
          }
          if(event.lastDate.length === 0){
            FormValid = false;
            setLastDateErr(true);
            scrrollArray.push('lastDate');
          }
          if(event.medium.length === 0){
            FormValid = false;
            setMediumErr(true);
            scrrollArray.push('medium')
          }
          if(event.state.length === 0){
            FormValid = false;
            setStateErr(true);
            scrrollArray.push('state');
          }
          if(event.city.length === 0){
            FormValid = false;
            setCityErr(true);
            scrrollArray.push('city');
          }
          if(event.startTime.length === 0){
            FormValid = false;
            setStartTimeErr(true);
            scrrollArray.push('startTime')
          }
          if(event.endTime.length === 0){
            FormValid = false;
            setEndTimeErr(true);
            scrrollArray.push('endTime')
          }
          if(scrrollArray.length > 0){
            console.log("scrrollArray", scrrollArray[0]);
            document.getElementById(scrrollArray[0]).scrollIntoView({ behavior:'smooth'});
          }

      return FormValid;
      }


      const handleCreateEvent = async (e) =>{
        setTitleErr(false);
        setViaErr(false);
        setCategoryErr(false);
        setVenueErr(false);
        setCampaignImgErr(false);
        setSponserImgErr(false);
        setPdfErr(false);
        setIsPaidErr(false);
        setAmountErr(false);
        setMinErr(false);
        setMaxErr(false);
        setMinMaxErr(false);
        setstartDateErr(false);
        setEndDateErr(false);
        setLastDateErr(false);
        setMediumErr(false);
        setStateErr(false);
        setCityErr(false);

        if(validate()){
        e.preventDefault();

        const{title, via, venue, description, campaign, sponser, pdf, youtube, paid, amount, 
        discount, minCapacity, maxCapacity, startDate, endDate, startTime, endTime, lastDate, medium, type,
        state, city, category} = event;
       
        const res = await fetch(process.env.REACT_APP_GOA_SPIRITUAL+`/event/create`, {
         method: "POST",
         headers: {
           "Content-Type" : "application/json"
         },
         body:JSON.stringify({
            event_title:title,
            event_via:via,
            event_venue:venue,
            event_description:description,
            event_images:campaign,
            sponsors:sponser,
            event_documents:pdf,
            event_youtube_video:youtube,
            is_paid:paid,
            event_amount:amount,
            event_discount:discount,
            event_min_capacity:minCapacity,
            event_max_capacity:maxCapacity,
            event_date:startDate,
            event_end_date:endDate,
            event_start_time:startTime,
            event_end_time:endTime,
            event_media:medium,
            event_type:type,
            cities:city,
            states:state,
            category: category,
            event_last_date_registration:lastDate,
            owner_entity_type: "EVENT",
            stateCode: "GA",
         })
       }).then(res=>res.json()).then(response=>{
        console.log(response);
        if(response.message==="Event added successfully"){
          EventAdded();
          setEvent({...event, title:"", category:"", via:"", venue:"", description:"", campaign:"", sponser:"", pdf:"", youtube:"", paid:"", amount:"", 
          discount:"", minCapacity:"", maxCapacity:"", startTime:"", endTime:"", lastDate:"",  medium:"", type:"",
          state:"", city:""});
          document.getElementById("startDateInput").value="";
          document.getElementById("startTimeInput").value="";
          document.getElementById("endDateInput").value="";
          document.getElementById("endTimeInput").value="";
          document.getElementById("lastDateInput").value="";

        }
        else if(response.message==="Event already exists"){
          EventAlready();
        }
        else{
          EventAddFail();
        }
       }).catch(err=>{
        console.log(err);
        EventAddFail();
       })
       
   }
      }
  return (
    <div className='addEvent'>
        <ToastContainer/>
    
    <Container data-aos="fade-up">
    <div className='d-flex align-items-center mb-4'>
        <Icon.BagPlus className='me-2' size={25}/>
        <h2 className='mb-0'>Add Event</h2>
        </div>
        <Row>
            <Col md={6}>
                <Form.Group className="mb-4" id='title'>
                    <Form.Label><span style={{color:'red'}}>*</span>Event Title</Form.Label>
                    <Form.Control name='title' placeholder="Enter event title"  value={event.title} onChange={ (e) => {
                  if(!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) && e.target.value.trim()){
                    handleEvent(e)
                  }
                  else if(e.target.value.length===0){
                    handleEvent(e)
                  }
                  // event.title.length>0 ? setTitleErr(false) : setTitleErr(true)
                }}/>
                {titleErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid Event Title</p>
                  ) : null}
                </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className="mb-4" id="via">
                <Form.Label><span style={{color:'red'}}>*</span>Event Registration Via</Form.Label>
                <Form.Select name='via' value={event.via} onChange={handleEvent}>
                    <option value='' selected disabled>Open this select menu</option>
                    <option value="online">Online Event Registration</option>
                    <option value="offline">Offline Event Registration</option>
                </Form.Select>
                {viaErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Select Event Registration Via </p>
                  ) : null}
                </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className="mb-4" id="category">
                <Form.Label><span style={{color:'red'}}>*</span>Event Category</Form.Label>
                <Form.Select name='category' value={event.category} onChange={handleEvent}>
                    <option value='' selected disabled>Open this select menu</option>
                    <option value="category-1">Category-1</option>
                    <option value="category-2">Category-2</option>
                </Form.Select>
                {categoryErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Select Event Category</p>
                  ) : null}
                </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className="mb-4" id="venue">
                    <Form.Label><span style={{color:'red'}}>*</span>Event Venue</Form.Label>
                    <Form.Control  placeholder="Enter event venue" name='venue' value={event.venue} onChange={ (e) => {
                   if(!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) && e.target.value.trim()){
                    handleEvent(e)
                  }
                  else if(e.target.value.length===0){
                    handleEvent(e)
                  }
               
                }}/>
                {venueErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Enter Valid Event Title</p>
                  ) : null}
                </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className="mb-4">
                    <Form.Label>Full Description</Form.Label>
                    <Form.Control type="text" placeholder="Enter event discription" name='description' value={event.description} onChange={handleEvent}/>
                </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className="mb-4" id="campaign">
                    <Form.Label><span style={{color:'red'}}>*</span>Promotional Campaign Photo</Form.Label>
                    <Form.Control type="file" accept="image/png, image/jpeg" id="Campaign-image" name="campaign" onChange={(eventImage)=>uploadImageToS3(eventImage,'campaign')}/>
                    {campaignImgErr ? (
                     <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>Please Upload Image</p>
                  ) : null}
                  <Row>
                    <Col xs={6} md={4}>
                    {event.campaign?
                   <Card style={{width:'100%', marginTop:'10px'}}>                   
                   <a href={event.campaign} target="_blank"><img src={event.campaign}  alt="Campaign Photo" className='card-img-top'/></a>
                   <Button className='btn-cancel' onClick={ () => {setEvent({ ...event,campaign:"" })
                  document.getElementById("Campaign-image").value =""} }><Icon.XCircle className="me-2"/>Cancel</Button>
                </Card>:""}
                    </Col>
                  </Row>
                </Form.Group>
               
            </Col>
            <Col md={6}>
            <Form.Group  className="mb-4" id="sponser">
                    <Form.Label><span style={{color:'red'}}>*</span>Promotional Campaign Sponsers</Form.Label>
                    <Form.Control type="file" accept="image/png, image/jpeg" id="Sponser-image" name="campaign" onChange={(eventImage)=>uploadImageToS3(eventImage,'sponser')} />
                    {sponserImgErr ? (
                     <p style={{color: "red", margin: "0px 20px", fontSize: "12px"}}>Please Upload Image</p>
                  ) : null}

                  <Row>
                    <Col xs={6} md={4}>
                {event.sponser?
                 <Card style={{width:'100%', marginTop:'10px'}} >                   
                    <a href={event.sponser} target="_blank"><img src={event.sponser}  alt="Sponser Photo" className='card-img-top'/></a>
                   <Button className='btn-cancel' onClick={ () => {setEvent({ ...event, sponser:"" })
                 document.getElementById("Sponser-image").value =""}}><Icon.XCircle className="me-2"/>Cancel</Button>
                </Card>:""} 
                </Col>
                </Row>
                </Form.Group>
            </Col>
            
            <Col md={6}>
                <Form.Group className="mb-4" id="pdf">
                    <Form.Label><span style={{color:'red'}}>*</span>Promotional Campaign PDF</Form.Label>
                    <Form.Control type="file" accept="image/pdf" id="pdf" name="campaign" onChange={(eventImage)=>uploadImageToS3(eventImage,'pdf')}/>
                    {pdfErr ? (
                     <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please Upload Image</p>
                  ) : null}

                  <Row>
                    <Col xs={6} md={4}>
                     {event.pdf ?
                 <Card style={{width:'100%', marginTop:'10px'}}>                   
                    <a href={event.pdf} target="_blank"><img src={event.pdf}  alt="pdf"  className='card-img-top'/></a>
                   <Button className='btn-cancel' onClick={ () => {setEvent({ ...event, pdf:"" })
                 document.getElementById("pdf").value = ""}}><Icon.XCircle className="me-2"/>Cancel</Button>
                </Card>:""} 
                </Col>
              </Row>
                </Form.Group>
            </Col>

            <Col md={6}>
                <Form.Group className="mb-4">
                    <Form.Label>YouTube URL</Form.Label>
                    <Form.Control type="text" placeholder="Enter event title" name='youtube' value={event.youtube} onChange={handleEvent}/>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-4" id='isPaid'>
                <Form.Label><span style={{color:'red'}}>*</span>Is Paid</Form.Label>
                <Form.Select name='paid' value={event.paid} onChange={handleEvent}>
                    <option value='' selected disabled>Open this select menu</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Form.Select>
                {isPaidErr ? (
                  <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Select, either event is paid or not </p>
                  ) : null}

                </Form.Group>
            </Col>

            <Col md={6}>
                <Form.Group className="mb-4" id="amount">
                    <Form.Label><span style={{color:'red'}}>*</span>Event Amount</Form.Label>
                    <Form.Control placeholder="Enter event Amout" name='amount' value={event.amount} onChange={(e)=>{
                  if (!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*|/\s/g/\-/?<>,;`'""/\\]/))
                  { handleEvent(e)}
                }}
                    disabled={event.paid==="yes"? false: true}/>
                     {amountErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please enter valid amount </p>
                  ) : null}
                </Form.Group>
                </Col>

                <Col md={6}>
                <Form.Group className="mb-4">
                    <Form.Label>Event Discount</Form.Label>
                    <Form.Control type="text" placeholder="Enter event discount" name='discount' value={event.discount} onChange={(e)=>{
                  if (!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/))
                  { handleEvent(e)}

                }}
              disabled={event.paid==="yes" ? false: true}/>
                </Form.Group>
                </Col>

                <Col md={6}>
                <Form.Group className="mb-4" id="min">
                    <Form.Label><span style={{color:'red'}}>*</span>Event Min Capacity</Form.Label>
                    <Form.Control  placeholder="Enter min capacity" name='min' value={event.minCapacity} onChange={(e)=>{
                  if (!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/))
                  { handleEvent(e)}
                }} />

                    {minErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please enter min capacity of event </p>
                  ) : null}
                </Form.Group>
                </Col>

                <Col md={6}>
                <Form.Group className="mb-4" id="max">
                    <Form.Label><span style={{color:'red'}}>*</span>Event Max Capacity</Form.Label>
                    <Form.Control placeholder="Enter max capacity" name='max' value={event.maxCapacity} onChange={(e)=>{
                  if (!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/))
                  { handleEvent(e)}
                }}/>

                {maxErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please enter max capacity of event </p>
                  ) : null} 
                  {minMaxErr ? (<p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Max capacity must be greater than min </p> ): null }
                </Form.Group>
               </Col>
               
               
               <Col md={6}>
                <Form.Group className='mb-4' id='startDate'>
                    <Form.Label><span style={{color:'red'}}>*</span>Event Start Date</Form.Label>
                    <Form.Control type='date' min="2023-02-01" id='startDateInput' name='startDate' onChange={ (e) => {
                        setEvent({ ...event,startDate:moment(e.target.value).format("YYYY-MM-DD") })
                    } } />
                    {startDateErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select event start date</p>
                  ) : null}
                </Form.Group>
               </Col>

               <Col md={6}>
                <Form.Group className='mb-4' id="startTime">
                <Form.Label><span style={{color:'red'}}>*</span>Event Start Time</Form.Label>
                    <Form.Control type='time' id="startTimeInput" name='startTime' onChange={handleEvent}/>
                    {startTimeErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select event start time</p>
                  ) : null}
                </Form.Group>
              
               </Col>
                
               <Col md={6}>
                <Form.Group className='mb-4' id='endDate'>
                    <Form.Label><span style={{color:'red'}}>*</span>Event End Date</Form.Label>
                    <Form.Control type='date' min={event.startDate}  id="endDateInput" name='endDate' onChange={ (e) => {
                        setEvent({ ...event,endDate:moment(e.target.value).format("YYYY-MM-DD") })
                    } } disabled={event.startDate?false:true}/>
                     {endDateErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select event end date</p>
                  ) : null}
                </Form.Group>
               </Col>

               <Col md={6}>
                <Form.Group className='mb-4' id="endTime">
                    <Form.Label>Event End Time</Form.Label>
                    <Form.Control type='time' id="endTimeInput" name='endTime' onChange={handleEvent}/>
                    {endTimeErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select event end time</p>
                  ) : null}
                </Form.Group>
               </Col>

               <Col md={6}>
                <Form.Group className='mb-4' id="lastDate">
                    <Form.Label><span style={{color:'red'}}>*</span>Event Last Date of Registration</Form.Label>
                    <Form.Control type='date' id="lastDateInput" name='lastDate' onChange={ (e) => {
                        setEvent({ ...event, lastDate:moment(e.target.value).format("YYYY-MM-DD") })
                    }}/>
                    {lastDateErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select last date of event registration</p>
                  ) : null}
                </Form.Group>
               </Col>

               <Col md={6}>
                <Form.Group className="mb-4" id='medium'>
                <Form.Label><span style={{color:'red'}}>*</span>Event Venue Medium</Form.Label>
                <Form.Select name='medium' value={event.medium} onChange={handleEvent}>
                    <option value="" selected disabled>Open this select menu</option>
                    <option value="OFFLINE">Offline</option>
                    <option value="ONLINE">Online</option>
                </Form.Select>
                {mediumErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select event medium</p>
                  ) : null}
                </Form.Group>
               </Col>
               <Col md={6}>
                <Form.Group className="mb-4">
                <Form.Label>Event Type</Form.Label>
                <Form.Select name="type" value={event.type} onChange={handleEvent}>
                    <option value='' selected disabled>Open this select menu</option>
                    <option value="INDIVIDUAL">Individual</option>
                    <option value="GROUP">Group</option>
                    <option value="BOTH">Both</option>
                </Form.Select>
                </Form.Group>
               </Col>
               <Col md={6}>
                <Form.Group className="mb-4" id='state'>
                <Form.Label><span style={{color:'red'}}>*</span>Select State</Form.Label>
                <Form.Select name='state' value={event.state} onChange={handleEvent}>
                    <option value='' selected disabled>Select any state</option>
                    <option value="Goa">Goa</option>
                </Form.Select>
                {stateErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select state</p>
                  ) : null}
                </Form.Group>
               </Col>
               <Col md={6}>
                <Form.Group className="mb-4" id="city">
                <Form.Label><span style={{color:'red'}}>*</span>Select City</Form.Label>
                <Form.Select name='city' value={event.city} onChange={handleEvent} disabled={event.state.length>0?false:true}>
                    <option value='' selected disabled>Select any city</option>
                    {districtData!==undefined && districtData.length>0 ? 
                     districtData.map((item,index)=><option key={index} value={item.name}>{item.name}</option>)
                    :<option>No data Found</option>}
                </Form.Select>
                {cityErr ? (
                    <p style={{color: "red", margin: "0px 20px", fontSize: "12px", }}>
                    Please select city</p>
                  ) : null}
                </Form.Group>
               </Col>

            <div className='text-center mb-4'>
               <Button className='btn btn-submit' onClick={handleCreateEvent}><Icon.CalendarPlus className='me-2'/>{ loader ? <Spinner animation="border" variant="light" /> : 'Add Event' }</Button>
             </div>
        </Row>
    </Container>

    </div>
  )
}

export default AddEvent;